











import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { useContent } from '~/composables';
import type { CmsBlock } from '~/modules/GraphQL/types';
import ContentBlock from './ContentBlock.vue';

export default defineComponent({
  name: 'ContentBlocks',
  components: {
    ContentBlock,
  },
  props: {
    identifiers: {
      type: Array as PropType<string[]>,
      required: true,
    },
    title: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  setup(props) {
    const {
      loadBlocks,
    } = useContent();
    const blocks = ref<CmsBlock[]>([]);

    useFetch(async () => {
      if (props.identifiers) {
        blocks.value = await loadBlocks({ identifiers: props.identifiers });
      }
    });

    return {
      blocks,
    };
  },
});
