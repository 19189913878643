






































import { computed, defineComponent, onMounted, ref, } from "@nuxtjs/composition-api";
import LazyHydrate from "vue-lazy-hydration";
import { onSSR } from "@vue-storefront/core";
import { CacheTagPrefix, useCache } from "@vue-storefront/cache";
import { SfBanner, SfBannerGrid, SfButton } from "@storefront-ui/vue";
import HeroSection from "~/components/HeroSection";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible";
import { useUser } from "~/modules/customer/composables/useUser";
import { useConfig, useContent } from "../composables";
import HTMLContent from "~/components/HTMLContent";
import ContentBlocks from "~/components/ContentBlocks";
import { CmsBlock } from "../modules/GraphQL/types";
import { MetaInfo } from "vue-meta";

export default defineComponent({
  name: 'HomePage',
  components: {
    ContentBlocks,
    HeroSection,
    LazyHydrate,
    LoadWhenVisible,
    SfBanner,
    SfBannerGrid,
    SfButton,
    HTMLContent,
    CallToAction: () => import(/* webpackPrefetch: true */ '~/components/CallToAction.vue'),
    InstagramFeed: () => import(/* webpackPrefetch: true */ '~/components/InstagramFeed.vue'),
    MobileStoreBanner: () => import(/* webpackPrefetch: true */ '~/components/MobileStoreBanner.vue'),
    BestSellers: () => import(/* webpackPrefetch: true */ '~/components/BestSellers.vue'),
    MessageBanner: () => import(/* webpackPrefetch: true */ '~/components/MessageBanner.vue'),
    BannerPromos: () => import(/* webpackPrefetch: true */ '~/components/molecules/BannerPromos.vue')
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { config }                = useConfig();
    const { addTags }               = useCache();
    const { isAuthenticated, user } = useUser();
    const { loadBlocks }            = useContent();
    const userName                  = computed(() => user?.value?.firstname ? user.value.firstname : '');

    const blocks                    = ref < CmsBlock[] > ([]);
    const blockBannerLogin          = ref('');
    const blockLoggedInBanner       = ref('');
    const blockServicesBanner       = ref('');
    const blockServicesBannerMobile = ref('');
    const isAuth                    = ref(false);

    onSSR(async () => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
      isAuth.value                    = await isAuthenticated();
      blocks.value                    = await loadBlocks({ identifiers: ['banner-login', 'loggedin-banner', 'homepage-services', 'homepage-services-mobile'] });
      blockBannerLogin.value          = blocks.value[0].content;
      blockLoggedInBanner.value       = blocks.value[1].content;
      blockServicesBanner.value       = blocks.value[2].content;
      blockServicesBannerMobile.value = blocks.value[3].content;
    });

    onMounted(async () => {
      isAuth.value                    = await isAuthenticated();
      blocks.value                    = await loadBlocks({ identifiers: ['banner-login', 'loggedin-banner', 'homepage-services', 'homepage-services-mobile'] });
      blockBannerLogin.value          = blocks.value[0].content;
      blockLoggedInBanner.value       = blocks.value[1].content;
      blockServicesBanner.value       = blocks.value[2].content;
      blockServicesBannerMobile.value = blocks.value[3].content;
    });

    // @ts-ignore
    return {
      isAuth,
      blockBannerLogin,
      blockLoggedInBanner,
      userName,
      blockServicesBanner,
      blockServicesBannerMobile,
      storeConfig: config
    };
  },
  head(): MetaInfo {
    const title   = this.$t("home_meta_title");
    const meta    = [];
    const myLinks = [];

    myLinks.push({
      hid: 'canonical',
      rel: 'canonical',
      href: this.$root.context.$config.vsfStoreUrl
    });

    meta.push({
      hid: 'description',
      name: 'description',
      content: this.$t("home_meta_description")
    });

    return {
      title,
      meta,
      link: [
        ...myLinks
      ],
    };
  },
});
